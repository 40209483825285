import {
  IconArrowRight,
  IconEvidence,
  Link,
  NavigationTiles,
  PreStyledComponents,
  Theme
} from "@life-without-barriers/react-components"

import React from "react"

const { Container } = PreStyledComponents
const { youthFull } = Theme

const pages = [
  {
    title: "Therapeutic Crisis Intervention (TCI)",
    showReadMore: true,
    href: "/our-approach/child-youth-and-family/tci-therapeutic-crisis-intervention/",
    rooted: true,
    copy: "This crisis prevention and intervention model teaches staff and carers how to help children learn constructive ways to handle crisis."
  },
  {
    title: "Multisystemic Therapy (MST)",
    showReadMore: true,
    href: "/services/child-youth-and-family/youth-justice/",
    copy: "This home and community-based intervention program is for at-risk youth, their families and communities. Life Without Barriers is the MST Network Partner for Australia and New Zealand."
  },
  {
    title: "Family involved",
    showReadMore: true,
    href: "/our-approach/child-youth-and-family/family-involved/",
    rooted: true,
    copy: "Our commitment to meaningful and ongoing family involvement aims to make sure children don’t lose their first and most enduring relationship."
  },
  {
    title: "MOCKINGBIRD FAMILY™",
    showReadMore: true,
    href: "/our-approach/child-youth-and-family/mockingbird-family/",
    rooted: true,
    copy: "By forming a local care community MOCKINGBIRD FAMILY™ improves the sense of connection and wellbeing of children, young people and their carers."
  }
]

const heading = (
  <div className="fw8 ts-display-5 ts-display-4-ns mr2">
    Learn more about how we care for children, young people and their families
    by working in ways that{" "}
    <Link
      className="fw8 black"
      to="/about-us/publications-and-resources/evidence-informed-strategy"
      rooted
    >
      evidence tells us
    </Link>{" "}
    makes a difference for them:
  </div>
)

const IndexPage = ({ currentPathname }: { currentPathname: string }) => (
  <div className="ph3 ph4-ns pv4 pv5-ns bg-lwb-theme-xxx-light">
    <Container>
      <NavigationTiles
        pages={pages}
        headingIcon={
          <IconEvidence color={youthFull.medium} height="48" width="48" />
        }
        currentPathname={currentPathname}
        heading={heading}
      />
    </Container>
    <div className="tc mt4 mb2">
      <Link
        className="fw8 color-lwb-theme-medium no-underline"
        to="/our-approach/child-youth-and-family/"
        rooted
        title="Learn more about our approach"
      >
        Explore our approach - Changing Lives, New Directions in Care{" "}
        <IconArrowRight
          height="8"
          width="5"
          color={youthFull.dark}
          className="ml1"
        />
      </Link>
    </div>
  </div>
)

export default IndexPage

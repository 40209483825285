import {
  Banner,
  Box,
  CardInline,
  ContactUsEmailLink,
  ContactUsPhoneLink,
  ContactUsUrlLink,
  Container,
  Heading,
  Helmet,
  Interfaces,
  Layout,
  Link,
  Quote,
  ReadMoreLink,
  Row,
  Section,
  StatsCard,
  Theme,
  TickListItem,
  YouTubeVideo,
  media,
  routesObject
} from "@life-without-barriers/react-components"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import { Site, getGatsbyImage } from "@life-without-barriers/gatsby-common"

import { contentfulHelpers as Contentful } from "@life-without-barriers/utilities"
import OurApproachRelatedPages from "../../../components/child-youth-and-family/OurApproachRelatedPages"
import React from "react"
import { ResourcesJson } from "../../../contentTypes"
import { graphql } from "gatsby"
import styled from "styled-components"

const { youthFull } = Theme

interface Props {
  location: { pathname: string }
  data: {
    site: Site
    bannerImage: IGatsbyImageData
    mstBloomImage: IGatsbyImageData
    resources: ResourcesJson
    mstBrochure: Interfaces.FileProps
    criminalResponsibilityBrochure: Interfaces.FileProps
    strategy2025doc: Contentful.RemoteFileAsset
  }
}

const YouthJusticePage = ({
  location,
  data: {
    site: { siteMetadata },
    bannerImage,
    mstBloomImage,
    resources,
    mstBrochure,
    criminalResponsibilityBrochure,
    strategy2025doc
  }
}: Props) => {
  const breadcrumbItems = [
    routesObject.home,
    routesObject.service,
    {
      to: "/services/child-youth-and-family/",
      text: "Child, youth and family",
      title: "Return to parent page"
    }
  ]

  const brochures = [mstBrochure, criminalResponsibilityBrochure]
  const getBrochureUrlByNameOrThrow = (name: string | undefined) => {
    if (!name) throw new Error("Brochure name required")
    const brochure = brochures.find((b) => b.edges[0].node.name === name)
    if (!brochure) throw new Error("Brochure not found for " + name)
    return brochure
  }

  return (
    <div>
      <Helmet
        title={`Youth justice services | ${siteMetadata.title}`}
        description="Our youth justice services support young people to break the offending cycle and become valued members of the community."
        image={bannerImage}
        siteUrl={siteMetadata.siteUrl}
        path={location.pathname}
      />
      <Layout theme={youthFull}>
        <Banner
          image={bannerImage}
          title="Youth justice"
          subTitle="At Life Without Barriers we work with young people so they can emerge into adulthood with safety, security and purpose."
          breadcrumbs={breadcrumbItems}
        />
        <Container>
          <Row>
            <Box className="layout-readable">
              <Heading size={2}>Supporting youth justice</Heading>
              <p>
                Our youth justice services support young people to gain the life
                skills they need to disrupt anti-social or offending behaviour,
                to access meaningful employment and help them to recognise that
                they are valued members of the community. To achieve this, we
                offer services for young people including transport, housing,
                education and training, mentoring and alcohol and drug
                counselling. We also deliver evidence-based programs known to
                effectively address offending and other behaviours that can be
                seen as anti-social.
              </p>
            </Box>
          </Row>
        </Container>
        <Section background="bg-lwb-grey-xxx-light">
          <Container>
            <Row>
              <Box className="layout-readable">
                <Heading size={2}>Why is it important?</Heading>
              </Box>
            </Row>
            <Row>
              <ul className="list flex flex-wrap pl0 mt4">
                <li className="w-50-m w-33-l mt3 mt0-ns ph2 ph3-ns">
                  <StatsCard
                    statisticValue="10"
                    explanation="the age of criminal responsibility begins in Australia"
                  />
                </li>
                <li className="w-50-m w-33-l mt3 mt0-ns ph2 ph3-ns">
                  <StatsCard
                    statisticValue="499"
                    explanation="children imprisoned last year alone"
                  />
                </li>
                <li className="w-50-m w-33-l mt3 mt0-l ph2 ph3-ns">
                  <StatsCard
                    statisticValue="25"
                    explanation="the age the rational part of your brain is fully developed"
                  />
                </li>
              </ul>
            </Row>
          </Container>
        </Section>
        <Container>
          <Row className="flex-wrap">
            <Box className="layout-readable">
              <Heading size={2}>
                Raising the minimum age of Criminal Responsibility
              </Heading>
              <p>
                Life Without Barriers currently provides support to over 2,200
                children in foster, kinship and residential care across the
                country, many of whom are vulnerable to having encounters with
                the criminal justice system. We recognise this is a serious
                problem and have therefore joined the campaign to raise the
                minimum age of criminal responsibility.
              </p>
              <p>
                In Australia, children as young as 10 can still be found guilty
                of a criminal offence, prosecuted, convicted and sentenced. In
                2020 alone, Australia imprisoned almost 500 children aged 10-13.
                These measures which are legalised in Australia now, are
                considered extremely harsh by international standards. In fact,
                the United Nations states that Australia currently lags behind
                the rest of the world in the age of criminal responsibility,
                with the global median age being 14.
              </p>
              <p>
                Statistics from the Australian Institute of Health and Welfare
                show that children who receive child protection services, are
                nine times more likely to come into contact with the youth
                justice system, and Aboriginal and Torres Strait Islander
                children are 17 times more likely to be involved in both child
                protection and the justice system. In fact, 65 percent of
                children who are in detention are Aboriginal and Torres Strait
                Islander.
              </p>
              <p>
                Through our work, we know that children who have experienced
                abuse, trauma or neglect are far more likely to offend than
                those who have not, and many children who offend have conditions
                which impact brain development including intellectual
                disability, developmental delay or acquired brain injury which
                is often linked to past trauma. These conditions reduce
                children’s ability to understand the impact of their actions and
                incarceration is known to have long term damaging impacts on
                children.
              </p>
              <p>
                Every child deserves to be healthy and to reach their potential.
                In order to achieve this and to create a lasting change within
                our communities, more support must be offered to ensure children
                receive help and support including better access to mental
                health care, mentoring, education and employment opportunities.
              </p>
              <p>
                Family-focused programs, like Multi-Systemic Therapy (MST) are
                reported to have the strongest current evidence of effectiveness
                in preventing or reducing youth offending. We work with people
                as young as 11 and their network of systems including family,
                peers, schools and neighbourhood through an approach that
                recognises the role of brain impairments on culpability, and on
                how criminal sanctions can entrench youth offending.
              </p>
              <Quote size="large" attribution="Claire Robbs, Chief Executive">
                &ldquo;Instead of imprisoning young children, and punishing them
                through traditional measures, we need to have services that are
                compassionate, adequately resourced and trauma-informed. We can
                do much better than prison for little children.&rdquo;
              </Quote>
              <p>
                Over the next 5 years, we will continue to engage in positive
                and constructive advocacy to improve social policies that impact
                people across a range of circumstances and communities in which
                we live and work. To learn more about this, you can read our{" "}
                <Link to={strategy2025doc.file.url}>Strategy 2025.</Link>
              </p>
            </Box>
            <Box className="layout-readable mt5">
              <Heading size={2}>What is Multisystemic Therapy (MST)?</Heading>
              <p>
                A family and home-based treatment that strives to decrease youth
                offending and prevent incarceration or placement in the care and
                protection system by empowering young people and families to
                function responsibly and successfully in their natural
                environments over the long term.
              </p>
              <p>
                MST targets young people between the ages of 11 and 18 who
                present with serious or escalating antisocial and problem
                behaviour that may include substance abuse and serious criminal
                offending. MST reduces youth offending and antisocial behaviour
                by addressing the core causes of such conduct—and views the
                client as a network of systems including family, peers, school,
                and neighbourhood. MST seeks to build the strength of the
                natural systems that are most influential on a young person’s
                behaviour and empowers caregivers to be long-term change agents,
                creating more sustainable effects than treatment methods focused
                solely on the individual.
              </p>
              <p>
                The model has been adapted or enhanced to address the needs of
                particular cohorts. Life Without Barriers can or does offer the
                following:
              </p>
              <p>
                <b>MST Prevention</b> includes enhancements to meet the needs of
                families where there is a risk of escalating child abuse or
                neglect behaviours. It supports high risk families to stay
                together in their homes by increasing the safety and well-being
                of all children in the home, decreasing the risk that children
                and young people will become more deeply involved in the child
                protection or youth justice systems.
              </p>
              <p>
                <b>MST-CAN</b> is an adaptation of the MST model designed to
                treat children aged 6 to 17 and their families who have come to
                the attention of child protection due to physical abuse and/or
                neglect and have been the subject of a substantiated report in
                the preceding 180 days. MST-CAN works with the entire family in
                an effort to keep children with their families and help the
                family resolve clinical and practical concerns so that children
                can be safe, prevent abuse and neglect, reduce mental health
                difficulties experienced by adults and children, and increase
                natural social supports.
              </p>
              <p>
                Common treatment strategies include safety planning, Cognitive
                Behavioural Therapies for managing anger and addressing the
                impact of trauma, Reinforcement-Based Therapy for adult
                substance misuse, family therapy focused on communication and
                problem solving, and sessions to support the parent in taking
                responsibility for the events that brought the family to child
                protection.
              </p>
              <p>
                MST and MST Prevention can be delivered as an early intervention
                strategy to prevent escalation of problem behaviours or as an
                alternative to custodial or out of home care placement where
                serious anti-social behaviour is present.
              </p>
              <YouTubeVideo
                className="mv4"
                videoId="CDbU5UnH8Yk"
                title="Why Multisystemic Therapy works at Life Without Barriers"
              />
              <Heading size={2} topMargin="standard">
                How is MST delivered?
              </Heading>
              <p>
                MST is delivered through a partnership arrangement with the
                program developers and provider agencies who deliver the
                interventions. As well as delivering MST, Life Without Barriers
                has been licenced to{" "}
                <Link to="/services/child-youth-and-family/mst-training">
                  support
                </Link>{" "}
                other agencies delivering MST, in Australia and New Zealand,
                through the provision of program start-up services, ongoing MST
                clinical support, quality assurance activities and
                organisational assistance. Life Without Barriers currently
                supports eight such teams in New Zealand and three in Australia.
                MST is implemented using the following framework:
              </p>
              <StyledList className="mv4 mb5-ns pl1 mb0">
                {[
                  "A single clinician working intensively with 4 to 6 families at a time",
                  "Team of 2 to 4 clinicians plus a Clinical Supervisor (Team Leader)",
                  "24hours / 7 days a week availability using an on-call system",
                  "3 to 5 months is the typical treatment time (4 months on average across cases)",
                  "Work is done in the community, home, school, and neighbourhood: removes barriers to service access"
                ].map((text, i) => (
                  <TickListItem
                    className={i === 0 ? "mt0" : "mt3"}
                    key={i}
                    text={text}
                  />
                ))}
              </StyledList>
              <Heading size={2}>Why does MST work?</Heading>
              <p>
                MST seeks to build the strength of the natural systems that are
                most influential on a youth&apos;s behaviour and therefore
                places a lower emphasis on the relationship between the
                clinician and the youth. The most critical therapeutic
                relationship is between the clinician and the parent or parental
                figure who manages the family -- the most powerful influence on
                the youth&apos;s behaviour.
              </p>
              <ul>
                <li>
                  Treatment targets the causal factors associated with offending
                  behaviour - criminogenic needs - family relations, peer
                  relations, school performance, community factors
                </li>
                <li>Occurs in the young person&apos;s natural environment</li>
                <li>Providers are accountable for outcomes</li>
                <li>Program structure removes barriers to service access</li>
                <li>Staff are well trained and supported</li>
                <li>
                  Significant energies are devoted to developing positive
                  interagency relations
                </li>
                <li>
                  Attention to model adherence and continuous quality
                  improvement
                </li>
              </ul>
              <p>
                MST will only work if we can effectively empower the young
                person&apos;s caregivers with the knowledge, skills and
                resources to effectively raise their children without the need
                for ongoing formal support from agencies.
              </p>
              <div className="center tc">
                <GatsbyImage
                  image={getGatsbyImage(mstBloomImage)}
                  alt=""
                  className="mv3 mv4-ns mh0-ns"
                />
              </div>
              <p className="mt0">
                This social ecological model demonstrates the various systems
                that influence youth behaviour and the relative power of that
                influence. The systems that play a greater role (quality and
                quantity of time spent) have a more long-term influence.
              </p>
              <p>
                Interactions are reciprocal, and may occur between any
                combination of the systems in which the youth and family are
                embedded. The MST clinician will strive to take all interactions
                into account when attempting to understand factors linked with
                problems behaviours.
              </p>
              <p>
                Relations between systems can indirectly predict behaviour (e.g.
                mum to teacher, parent to peer’s parent, etc.).
              </p>
              <p>
                To learn more about Life Without Barriers Youth justice services
                call or email us on:
              </p>
              <Row className="flex-wrap">
                <Box className="w-100 w-50-ns">
                  <ContactUsPhoneLink phoneNumber="0293079311" />
                </Box>
                <Box className="w-100 w-50-ns">
                  <ContactUsEmailLink
                    text="suellen.lembke@lwb.org.au"
                    link="mailto:suellen.lembke@lwb.org.au"
                  />
                </Box>
              </Row>
              <Heading size={2} topMargin="standard">
                MST-CAN
              </Heading>
              <p>
                Multisystemic Therapy for Child Abuse and Neglect (MST-CAN®) was
                developed to address underlying trauma that results in harm to
                children, young people and families who have come to the
                attention of Child Protective Services.
              </p>
              <p>
                It is a 24/7 home-based treatment model for families with
                substantiated cases of physical abuse and/or neglect of children
                and young people between 6 and 17 years. Services are provided
                to all family members. The model targets children where a report
                of physical abuse and/or neglect has been received within 180
                days of the referral.
              </p>
              <p>
                Treatment addresses multiple factors known to be related to
                physical abuse and/or neglect and seeks to promote behavioral
                change within the family.
              </p>
              <p>For more information visit MST-CAN website or email us on:</p>
              <Row className="flex-wrap">
                <Box className="w-100 w-50-ns">
                  <ContactUsUrlLink
                    text="mstcan.com"
                    link="http://www.mstcan.com"
                    ariaLabel="Visit mstcan.com"
                  />
                </Box>
                <Box className="w-100 w-50-ns">
                  <ContactUsEmailLink
                    text="ingrid.altena2@lwb.org.au"
                    link="mailto:ingrid.altena2@lwb.org.au"
                  />
                </Box>
              </Row>
              <ReadMoreLink
                text="Download MST-CAN Brochure"
                href={mstBrochure.edges[0].node.publicURL}
                className="mt4 pr4-l"
              />
            </Box>
          </Row>
        </Container>
        <Section background="bg-hex-image">
          <Container>
            <Row>
              <Box>
                <Heading size={2}>Resources</Heading>
              </Box>
            </Row>
            <Row className="mt4 flex-wrap">
              {resources.edges
                .sort((a, b) =>
                  (a.node.order ?? 0) > (b.node.order ?? 0) ? 1 : -1
                )
                .map(({ node }, i: number) => (
                  <CardInline
                    key={i}
                    href={
                      getBrochureUrlByNameOrThrow(node.brochureName).edges[0]
                        .node.publicURL
                    }
                    image={node.image.childImageSharp.gatsbyImageData}
                    title={node.title}
                    ga={{
                      eventCategory: "Youth Justice",
                      eventLabel: node.title
                    }}
                  />
                ))}
            </Row>
          </Container>
        </Section>
        <OurApproachRelatedPages currentPathname={location.pathname} />
      </Layout>
    </div>
  )
}

export const query = graphql`
  {
    bannerImage: file(
      relativePath: { regex: "/youth-justice-banner-cropped.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1200, quality: 90)
      }
    }
    mstBloomImage: file(
      relativePath: { regex: "/youth-justice-mst-bloom.png/" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 350, quality: 90)
      }
    }
    mstBrochure: allFile(filter: { name: { eq: "MST-CAN-Brochure" } }) {
      edges {
        node {
          name
          publicURL
        }
      }
    }
    criminalResponsibilityBrochure: allFile(
      filter: { name: { eq: "Criminal-Responsibility-Policy" } }
    ) {
      edges {
        node {
          name
          publicURL
        }
      }
    }
    strategy2025doc: contentfulAsset(title: { eq: "Strategy 2025" }) {
      ...RemoteContentfulAsset
    }
    resources: allResourcesJson(
      filter: { subsite: { eq: "child-youth-and-family" } }
    ) {
      edges {
        node {
          order
          title
          copy
          brochureName
          image {
            childImageSharp {
              gatsbyImageData(width: 600)
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`

const StyledList = styled.ul`
  ${media.notSmall`
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  `}
`

export default YouthJusticePage
